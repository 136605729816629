@import "../../Shared/Theme.scss";

#dark-background {
    animation: fadeToBlack 1s ease-in-out forwards;
    background-color: $dark-mode-background-color;
    position: relative;

    .image-container {
        padding: 40px;
        padding-bottom: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: auto;

        //determines the size of the carousel that shows images
        //change this to fix UX of exhibit sizing
        .carousel {
            max-height: 100vh;
            max-width: 100vh;
        }

        .exhibitImageSingle {
            //size the image within the screen regardless of resolution
            max-width: 90vw;
            max-height: 70vh;
            width: auto;
            height: auto;
        }
    }
}

.galleryButton {
    margin: 0px;
    right: 20px;
    bottom: 20px;
    position: absolute !important;
    z-index: 99;
}

@keyframes fadeToBlack {
    0% {
        background-color: $background-color;
    }

    100% {
        background-color: $dark-mode-background-color;
    }
}
