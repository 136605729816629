@import "../Shared/Theme.scss";
$breakpoint-tablet: 960px;

$miniatureBannerSlim: url("../../Images/BannerImages/ClockworkDragonBannerHighContrast.jpg");
$miniatureBannerLong: url("../../Images/BannerImages/SludgeMonstrosity.jpg");

$terrainBannerSlim: url("../../Images/BannerImages/DocksSmall Banner.jpg");
$terrainBannerLong: url("../../Images/BannerImages/PrismaWallBanner.jpg");

$modelKitsBannerSlim: url("../../Images/BannerImages/GundamChibiBanner.jpg");
$modelKitsBannerLong: url("../../Images/BannerImages/GoldUnicornBanner.jpg");

$otherBannerSlim: url("../../Images/BannerImages/MothersDayDiorama.jpg");
$otherBannerLong: url("../../Images/BannerImages/CoinBanner.jpg");


main {
    .gridContainer {
        min-height: 100%;
        margin: 0;
        width: 100%;
        padding: 20px 10% 20px 10%;

        justify-content: center;

        .imageContainer {
            margin: 20px;

            .previewLink {
                box-shadow: 0 0 20px rgb(121, 121, 121);
                //Sizes the images to fit on screen properly, and then adjusts the size ratio to fix stretching
                display: block;
                //ratio is 2.42105263158
                max-width: 690px;
                max-height: 285px;
                width: auto;
                height: auto;
                border-radius: 5px;

                cursor: pointer;
                margin: auto;

                &:hover {
                    transition: 0.3s ease-in-out;
                    transform: scale(1.2);
                    /* (120% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
                }
            }
        }
    }

    //classes are dynamically build from state, and then the background image is set here
    .largeminiatures {
        background-image: $miniatureBannerLong;
        @include left-align-text;
    }
    .slimminiatures {
        background-image: $miniatureBannerSlim;
        @include center-text;

        .bannerText {
            text-shadow: 0 0 20px black, 0 0 20px black, 0 0 20px black;
        }
    }
    .largeterrain {
        background-image: $terrainBannerLong;
        @include center-text;

        .bannerText {
            text-align: center;
            transform: translate(-50%, -30%);
            text-shadow: 0 0 20px black, 0 0 20px black, 0 0 20px black;
        }
    }
    .slimterrain {
        background-image: $terrainBannerSlim;
        @include center-text;
    }

    .largemodelkits {
        background-image: $modelKitsBannerLong;
        @include center-text;

        .bannerText {
            text-align: center ;
            transform: translate(-50%, -30%);
            text-shadow: 0 0 20px black, 0 0 20px black, 0 0 20px black;
        }
    }
    .slimmodelkits {
        background-image: $modelKitsBannerSlim;
        @include center-text;
    }

    .largeother {
        background-image: $otherBannerLong;
        @include left-align-text;

        #overlay {
            background-color: rgba(0, 0, 0, 0.25);
            height: 100%;
            width: 100%;
        }
    }

    .slimother {
        background-image: $otherBannerSlim;
        @include center-text;
    }

    .paginationController {
        button {
            font-size: 20px;
        }
    }
    @media (max-width: $breakpoint-tablet) {
        .gridContainer {
            .imageContainer {
                width: 100%;

                .previewLink {
                    //Sizes the images to fit on screen properly, and then adjusts the size ratio to fix stretching
                    max-width: 100%;
                }
            }
        }
    }
}
