@import './../Theme.scss';

.footer {
    //will always be on bottom because main wrapper fills in all available space
    background-color: rgb(59, 64, 69);
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 7px 0px 7px 0px !important;

    @media (max-width: $breakpoint-tablet) {
        flex-direction: column;
    }

    .footerLinkContainer {
        .footerLink {
            font-size: large;
            text-align: center;
            margin-right: 20px;

            .icon {
                margin-right: 4px;
                color: white;
            }

            .text {
                margin: 0px;
                color: white;
                font-size: 14px;
            }

            .githubLinkFix {
                padding: 0px 2px 4px 2px;
            }
        }
    }
}