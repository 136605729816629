$primary-color: white;
$background-color: rgb(234, 237, 237);
$dark-mode-background-color: #3b4045;
$black: rgb(45,45,45);
$breakpoint-tablet: 960px;
$header-height: 72px;
$logo-primary: #9bc63d;
$logo-secondary: #e65a34;


html,
body,
#root,
#root>div,
.App {

  /* The below code is for properly sizing the main content container of the page
    and accounts for the header size and the footer size. This pushes
    the footer to the bottom of the page regardless of height 
    without absolute positioning needed, and allows the website to have a 
    default background color for all pages.
    Minimum height will be the size of the viewport, but it can expand to 
    fit the content of the page while keeping the footer below the content */

  // height: 100vh;
  margin: 0;
  padding: 0;

  #main-wrapper {
    background-color: rgb(234, 237, 237);

    /* will at least cover the rest of the screen minus the header and footer */
    min-height: calc(100vh - #{$header-height} - 38px);

    @media (max-width: $breakpoint-tablet) {
      min-height: calc(100vh - #{$header-height} - 62px);
    }

    main {
      /* will cover the rest of the screen minus the header and footer*/
      min-height: calc(100vh - #{$header-height} - 38px);

      @media (max-width: $breakpoint-tablet) {
        min-height: calc(100vh - #{$header-height} - 62px);
      }
    }
  }

  .navbar.darkMode,
  .footer.darkMode {
      background-color: $black;
      animation: fadeToBlackHeaderFooter 1s ease-in-out forwards;

      svg {
          fill: white;
      }
  }
}

@mixin center($width: null, $height: null) {
  position: absolute;
  top: 50%;
  left: 50%;

  @if not $width and not $height {
    transform: translate(-50%, -50%);
  } @else if $width and $height {
    width: $width;
    height: $height;
    margin: -($width / 2) #{0 0} (-($height / 2));
  } @else if not $height {
    width: $width;
    margin-left: -($width / 2);
    transform: translateY(-50%);
  } @else {
    height: $height;
    margin-top: -($height / 2);
    transform: translateX(-50%);
  }
}

@mixin center-text {
  .bannerText {
      width: 80%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      padding: 0px;
      text-shadow: 0 0 20px black;

      .title,
      .flavorText {
          max-width: 562px;
          margin: 0 auto;
          margin-bottom: 0.35em;
      }
  }
}

@mixin left-align-text {
  .bannerText {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      padding-left: 5%;
      text-shadow: 0 0 20px black;;


      .flavorText {
          max-width: 562px;
      }
  }
}

@keyframes fadeToBlack {
  0% {
      background-color: $background-color;
  }

  100% {
      background-color: $dark-mode-background-color;
  }
}

@keyframes fadeToBlackHeaderFooter {
  0% {
      background-color: $background-color;
  }

  100% {
      background-color: $black;
  }
}