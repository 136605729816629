$LongBanner: url('../../Images/BannerImages/ZoomedBannerPurpleWorm4.jpg');
$SlimBanner: url('../../Images/BannerImages/FullBannerPurpleWormSlim.jpg');
$breakpoint-tablet: 960px;
@import '../Shared/Theme.scss';

main{
    background-color: #eaeded;
    min-height: 100%;
    min-width: 253px;
}
.linkCardsContainer {
    width: 100%;
    min-height: 100%;

    .linksGrid {
        width: 100%;
        max-width: 1500px;
        padding: 5px;
        padding-top: 20px;
        height: 100%;
        margin: 0;

        @media (min-width: 1500px) {
            margin: auto;
        }

        .expand {
            max-width: 100%;
            flex-basis: 100%;
        }
    }
}

@media (min-width:990px) {
    .homeBackgroundImage {
        background-image: $LongBanner;
        @include left-align-text;
    }
}

@media (max-width:990px) {
    .homeBackgroundImage {
        background-image: $SlimBanner;
        @include center-text;
    }
}

@media (max-width: $breakpoint-tablet) {
    #main-wrapper {
        padding-bottom: 68px;
    }
}